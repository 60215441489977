<template>
  <div class="update-form">
    <div class="emp">
      <a-avatar :size="54" :src="gteData.photoUrl">
        <template #icon>
          <UserOutlined />
        </template>
      </a-avatar>
      <div class="emp-info">
        <div class="name">{{ gteData.staffFullName }}</div>
        <div class="id-number">
          员工ID：
          <span class="value">{{ gteData.idNumber }}</span>
        </div>
      </div>
    </div>
    <div class="btn-row">
      <div class="left">
        <a-button type="primary" @click="openEmpInfo">个人信息</a-button>
      </div>
      <div class="right">
        <a-space>
          <a-button
            type="primary"
            :disabled="isFirst"
            @click="turnClick('prev')"
          >
            上一个
          </a-button>
          <a-button
            type="primary"
            :disabled="isLast"
            @click="turnClick('next')"
          >
            下一个
          </a-button>
          <a-button type="primary" ghost @click="cancelClick">取消</a-button>
          <a-button
            type="primary"
            :disabled="saveDisabled"
            @click="openSaveModal"
            >保存
          </a-button>
        </a-space>
      </div>
    </div>
    <a-form
      layout="vertical"
      :model="formState"
      :rules="formRules"
      ref="formRef"
    >
      <div class="readonly-data-wrap">
        <div class="readonly-data">
          <span class="label">Timecard Issue-Missing:</span>
          <span class="value">{{ formState.timecardIssueMissing }}</span>
        </div>
        <div class="readonly-data">
          <span class="label">Timecard Issue-Reject:</span>
          <span class="value">{{ formState.timecardIssueReject }}</span>
        </div>
      </div>
      <div class="readonly-data">
        <span class="label">Bench:</span>
        <span class="value">{{ formState.bench }}</span>
      </div>
      <div class="collapse-row readonly-data">
        <div>
          <span class="label">Operaton ARVE:</span>
          <span class="value">{{ formState.operationArve }}</span>
        </div>
        <a-button type="link" @click="collapse = !collapse"
          >关联数据
          <UpOutlined v-if="!collapse" />
          <DownOutlined v-else />
        </a-button>
      </div>
      <div v-if="!collapse" class="collapse-data">
        <div class="left">
          <span class="label">BT:</span>
          <span class="value">{{ gteData.billableTime }}</span>
        </div>
        <div class="right">
          <span class="label">Grand Total:</span>
          <span class="value">{{ gteData.grandTotal }}</span>
        </div>
        <div class="left">
          <span class="label">NBT:</span>
          <span class="value">{{ gteData.nonBillableTime }}</span>
        </div>
        <div class="right">
          <span class="label">Annual Leave:</span>
          <span class="value">{{ gteData.annualLeave }}</span>
        </div>
        <div class="left">
          <span class="label">WWOC:</span>
          <span class="value">{{ gteData.wwoc }}</span>
        </div>
        <div class="right">
          <span class="label">Public Holiday:</span>
          <span class="value">{{ gteData.publicHoliday }}</span>
        </div>
        <div class="left">
          <span class="label">Funding:</span>
          <span class="value">{{ gteData.funding }}</span>
        </div>
        <div class="right">
          <span class="label">Unpaid Leave:</span>
          <span class="value">{{ gteData.unpaidLeave }}</span>
        </div>
      </div>
      <a-form-item label="BT(BT+NBT,WWOC,Funding)" name="btNbtWwocFunding">
        <a-input
          style="width: 128px"
          v-model:value="formState.btNbtWwocFunding"
          @blur="calculateBTAV"
        />
      </a-form-item>
      <a-form-item label="AV(Annual,No Pay Leave)" name="annualNopayleave">
        <a-input
          style="width: 128px"
          v-model:value="formState.annualNopayleave"
          @blur="calculateBTAV"
        />
      </a-form-item>
      <a-form-item label="RM Type" name="rmType">
        <a-select
          style="width: 240px"
          v-model:value="formState.rmType"
          placeholder="请选择"
          show-search
          allowClear
        >
          <a-select-option
            v-for="item in rmTypeOps"
            :key="item.value"
            :value="item.value"
          >
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="RM Comments" name="rmComments">
        <a-textarea
          v-model:value="formState.rmComments"
          placeholder="请输入"
          :rows="2"
          showCount
          :maxlength="70"
        />
      </a-form-item>
      <div class="readonly-data">
        <span class="label">BT FCST:</span>
        <span class="value">{{ formState.btFcst }}</span>
      </div>
      <div class="readonly-data">
        <span class="label">BT FCST vs Actual BT GAP:</span>
        <span class="value">{{ formState.btGap }}</span>
      </div>
    </a-form>
  </div>
  <SaveModal
    :visible="saveVisible"
    :btn-type="btnType"
    @close-modal="closeSaveModal"
    @save-click="saveClick"
  />
  <TurnModal
    :visible="turnVisible"
    :btn-type="btnType"
    @close-modal="closeTurnModal"
    @turn-click="turnFunc"
  />
</template>

<script>
import { defineComponent, onMounted, ref, watch } from "vue";
import { UserOutlined, UpOutlined, DownOutlined } from "@ant-design/icons-vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import { computed } from "@vue/runtime-core";
import { dictionaryOptionsCode } from "@/utils/common";
import { message } from "ant-design-vue";
import SaveModal from "@/views/home/ARVE/GTEData/components/SaveModal";
import TurnModal from "@/views/home/ARVE/GTEData/components/TurnModal";

export default defineComponent({
  name: "UpdateForm",
  props: {
    gteData: Object,
    isFirst: Boolean,
    isLast: Boolean,
  },
  emits: [
    "close-drawer",
    "refresh-table",
    "open-emp-info",
    "prev-click",
    "next-click",
  ],
  components: {
    TurnModal,
    SaveModal,
    UserOutlined,
    UpOutlined,
    DownOutlined,
  },
  setup(props, { emit }) {
    const { $api } = useGlobalPropertyHook();

    const collapse = ref(false);

    const formRef = ref();
    const formState = ref({});
    const oldData = ref({});
    watch(
      () => props.gteData.arveNO,
      () => {
        formState.value = {
          arveNO: props.gteData.arveNO,
          timecardIssueMissing: props.gteData.timecardIssueMissing,
          timecardIssueReject: props.gteData.timecardIssueReject,
          bench: props.gteData.bench,
          operationArve: props.gteData.operationArve,
          btFcst: props.gteData.btFcst,
          btGap: props.gteData.btGap,
          btNbtWwocFunding: "" + props.gteData.btNbtWwocFunding,
          annualNopayleave: "" + props.gteData.annualNopayleave,
          rmType: props.gteData.rmType ? "" + props.gteData.rmType : undefined,
          rmComments: props.gteData.rmComments,
        };
        oldData.value = {
          btNbtWwocFunding: "" + props.gteData.btNbtWwocFunding,
          annualNopayleave: "" + props.gteData.annualNopayleave,
          rmType: props.gteData.rmType ? "" + props.gteData.rmType : undefined,
          rmComments: props.gteData.rmComments,
        };
      },
      { immediate: true }
    );
    const checkHour = (rule, value) => {
      if (value - 248 > 0) {
        return Promise.reject(
          "请填写小时数，允许小数点后一位（请检查数据是否正确）"
        );
      } else {
        return Promise.resolve();
      }
    };
    const formRules = computed(
      // () => formState.value.btNbtWwocFunding,
      () => {
        if (
          formState.value.btNbtWwocFunding === oldData.value.btNbtWwocFunding
        ) {
          return {
            btNbtWwocFunding: [
              {
                required: true,
                message: "请输入BT",
                trigger: "blur",
              },
              {
                pattern: /^-?[0-9]+(\.[0-9])?$/,
                message: "请填写小时数，允许小数点后一位（请检查数据是否正确）",
                trigger: "blur",
              },
              {
                validator: checkHour,
                trigger: "blur",
              },
            ],
            annualNopayleave: [
              {
                required: true,
                message: "请输入AV",
                trigger: "blur",
              },
              {
                pattern: /^-?[0-9]+(\.[0-9])?$/,
                message: "请填写小时数，允许小数点后一位（请检查数据是否正确）",
                trigger: "blur",
              },
              {
                validator: checkHour,
                trigger: "blur",
              },
            ],
          };
        }
        return {
          btNbtWwocFunding: [
            {
              required: true,
              message: "请输入BT",
              trigger: "blur",
            },
            {
              pattern: /^-?[0-9]+(\.[0-9])?$/,
              message: "请填写小时数，允许小数点后一位（请检查数据是否正确）",
              trigger: "blur",
            },
            {
              validator: checkHour,
              trigger: "blur",
            },
          ],
          annualNopayleave: [
            {
              required: true,
              message: "请输入AV",
              trigger: "blur",
            },
            {
              pattern: /^-?[0-9]+(\.[0-9])?$/,
              message: "请填写小时数，允许小数点后一位（请检查数据是否正确）",
              trigger: "blur",
            },
            {
              validator: checkHour,
              trigger: "blur",
            },
          ],
          rmType: [
            {
              required: true,
              message: "请输入RM Type",
              trigger: "change",
            },
          ],
          rmComments: [
            {
              required: true,
              message: "请输入AV",
              trigger: "blur",
            },
          ],
        };
      }
    );
    const rmTypeOps = ref([]);
    // 获取筛选框字典
    const getSelectOptions = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "questionType",
          },
        })
        .then((res) => {
          rmTypeOps.value = dictionaryOptionsCode(res.data.questionType);
        });
    };
    let oldBt, oldAv;
    const calculateBTAV = () => {
      formRef.value
        .validate(["btNbtWwocFunding", "annualNopayleave"])
        .then(() => {
          const bt = formState.value.btNbtWwocFunding;
          const av = formState.value.annualNopayleave;
          if (bt === oldBt && av === oldAv) return;
          const params = {
            arveNo: props.gteData.arveNO,
            bt,
            av,
          };
          $api.calculateBTAV(params).then((res) => {
            formState.value.operationArve = res.data.OperationARVE;
            formState.value.btGap = res.data.btGap;
            oldBt = bt;
            oldAv = av;
          });
        });
    };
    const saveDisabled = computed(() => {
      return (
        formState.value.btNbtWwocFunding == oldData.value.btNbtWwocFunding &&
        formState.value.annualNopayleave == oldData.value.annualNopayleave &&
        formState.value.rmType == oldData.value.rmType &&
        formState.value.rmComments == oldData.value.rmComments
      );
    });
    const cancelClick = () => {
      emit("close-drawer");
    };
    const saveClick = (sendNotice, operate) => {
      const data = {
        arveNo: formState.value.arveNO,
        btNbtWwocFunding: formState.value.btNbtWwocFunding,
        annualNopayleave: formState.value.annualNopayleave,
        operationArve: formState.value.operationArve,
        rmType: formState.value.rmType,
        rmComments: formState.value.rmComments,
        btFcst: formState.value.btFcst,
        btGap: formState.value.btGap,
      };
      const params = {
        sendNotice: sendNotice ? "1" : "0",
      };
      $api.saveGTEData(data, params).then(() => {
        message.success("保存成功");
        emit("refresh-table");
        closeSaveModal();
        if (operate === "prev") {
          emit("prev-click");
        } else if (operate === "next") {
          emit("next-click");
        } else {
          oldData.value = {
            btNbtWwocFunding: formState.value.btNbtWwocFunding,
            annualNopayleave: formState.value.annualNopayleave,
            rmType: formState.value.rmType,
            rmComments: formState.value.rmComments,
          };
        }
      });
    };
    const openEmpInfo = () => {
      emit("open-emp-info");
    };

    const saveVisible = ref(false);
    const turnVisible = ref(false);
    const btnType = ref();
    const openSaveModal = (operate) => {
      btnType.value = operate;
      formRef.value
        .validate()
        .then(() => {
          saveVisible.value = true;
        })
        .catch(() => {
          turnVisible.value = true;
        });
    };
    const closeSaveModal = () => {
      saveVisible.value = false;
    };
    const closeTurnModal = () => {
      turnVisible.value = false;
    };
    const turnFunc = (operate) => {
      turnVisible.value = false;
      formRef.value.clearValidate();
      if (operate === "prev") {
        emit("prev-click");
      } else {
        emit("next-click");
      }
    };

    const turnClick = (operate) => {
      if (saveDisabled.value) {
        turnFunc(operate);
      } else {
        openSaveModal(operate);
      }
    };

    onMounted(() => {
      getSelectOptions();
    });

    return {
      formRef,
      formState,
      formRules,
      rmTypeOps,
      calculateBTAV,
      saveDisabled,
      cancelClick,
      saveClick,
      openEmpInfo,
      collapse,
      saveVisible,
      btnType,
      openSaveModal,
      closeSaveModal,
      turnClick,
      turnVisible,
      closeTurnModal,
      turnFunc,
    };
  },
});
</script>

<style lang="less" scoped>
.update-form {
  padding: 0 16px;
  height: 100%;
  overflow: auto;

  .emp {
    display: flex;

    .emp-info {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 10px;

      .name {
        font-size: 24px;
        line-height: 34px;
        color: #505050;
      }

      .id-number {
        font-weight: 400;
        font-size: 16px;
      }

      .value {
        color: #12abdb;
      }
    }
  }

  .btn-row {
    display: flex;
    margin: 24px 0;

    .left {
      width: 50%;
    }

    .right {
      width: 50%;
      display: flex;
      justify-content: flex-end;
    }
  }

  .readonly-data {
    line-height: 25px;
    margin-bottom: 8px;
  }

  .value {
    padding-left: 20px;
    color: #999999;
  }

  .readonly-data-wrap {
    display: flex;

    .readonly-data {
      min-width: 50%;
    }
  }

  .collapse-row {
    height: 28px;
    line-height: 28px;
    background: #e9f6fe;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .value {
      padding-left: 20px;
      color: #12abdb;
    }
  }

  .collapse-data {
    margin-bottom: 8px;
    padding: 12px 20px;
    background: #fafafa;
    display: flex;
    flex-wrap: wrap;
    line-height: 150%;

    .left {
      width: 40%;
    }

    .right {
      width: 60%;
    }
  }
}
</style>
