<template>
  <div ref="turnModalRef">
    <a-modal
      :visible="visible"
      @cancel="closeModal"
      :width="520"
      centered
      :getContainer="() => $refs.turnModalRef"
    >
      <template #footer>
        <div class="footerBtn">
          <a-button type="primary" ghost @click="closeModal">取消</a-button>
          <a-button
            type="primary"
            @click="turnClick('prev')"
            v-show="btnType === 'prev'"
          >
            上一个
          </a-button>
          <a-button
            type="primary"
            @click="turnClick('next')"
            v-show="btnType === 'next'"
          >
            下一个
          </a-button>
        </div>
      </template>
      <div class="save-content">
        <div>
          <div class="title">页面有修改项没有保存，是否继续切换</div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "TurnModal",
  props: {
    visible: Boolean,
    btnType: String,
  },
  emits: ["close-modal", "turn-click"],
  components: {},
  setup(props, { emit }) {
    const closeModal = () => {
      emit("close-modal");
    };

    const turnClick = (operate) => {
      emit("turn-click", operate);
    };

    return {
      closeModal,
      turnClick,
    };
  },
});
</script>

<style lang="less" scoped>
.footerBtn {
  text-align: center;
  margin: 5px;
}

.save-content {
  margin: 40px 0 0;
  text-align: center;

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 24px;
  }

  .ant-checkbox-wrapper {
    font-size: 18px;
  }
}
</style>
