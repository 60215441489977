<template>
  <section class="app-container">
    <div class="arve-tip">{{ arveTip }}</div>
    <div class="search">
      <div class="form">
        <div class="label">精确搜索：</div>
        <a-input-search
          class="item"
          placeholder="请输入人名"
          style="width: 284px"
          @search="doSearch"
          v-model:value="formState.fullName"
          allowClear
          autocomplete="off"
        />
      </div>
      <div>
        <a-button type="link" style="margin-right: 13px" @click="collapseClick" v-auth="['system:GTEData:collapse']"
        >筛选项
          <UpOutlined v-if="!collapse" />
          <DownOutlined v-else />
        </a-button>
        <a-button type="primary" @click="exportClick" v-auth="['system:GTEData:export']">导出</a-button>
      </div>
    </div>
    <div v-if="!collapse" class="filter">
      <div class="form">
        <div class="label">数据筛选：</div>
        <a-select
          class="item"
          style="width: 240px"
          v-model:value="formState.queryType"
          @change="doSearch"
          placeholder="请选择"
          show-search
          allowClear
        >
          <a-select-option key="1" value="1">operation小于100%</a-select-option>
          <a-select-option key="2" value="2"
          >07 Timecard Issue-Missing大于0
          </a-select-option>
          <a-select-option key="3" value="3"
          >07 Timecard Issue-Reject大于0
          </a-select-option>
          <a-select-option key="4" value="4"
          >FCST BT vs Actual BT GAP不为0
          </a-select-option>
        </a-select>
        <div class="label">Team：</div>
        <a-select
          class="item"
          style="width: 240px"
          v-model:value="formState.orgThreeTwo"
          placeholder="请选择"
          @change="orgThreeTwoChange"
          allowClear
          show-search
        >
          <a-select-option
            v-for="(item, index) in orgThreeTwoOps"
            :key="index"
            :value="item"
          ><span :title="item">{{ item }}</span>
          </a-select-option>
        </a-select>
        <div class="label">Offering：</div>
        <a-select
          class="item"
          style="width: 240px"
          v-model:value="formState.orgFourOneOne"
          placeholder="请选择"
          @change="doSearch"
          allowClear
        >
          <a-select-option
            v-for="(item, index) in orgFourOneOneOps"
            :key="index"
            :value="item"
          ><span :title="item">{{ item }}</span>
          </a-select-option>
        </a-select>
      </div>
      <div class="form">
        <div class="label">月份：</div>
        <a-month-picker
          class="item"
          style="width: 240px"
          v-model:value="formState.time"
          :disabled-date="disabledDate"
          placeholder="请选择"
          @change="doSearch"
          :allowClear="false"
        />
        <div class="label">职级：</div>
        <SelectWithAll
            class="item"
            style="width: 240px"
            v-model:value="formState.grade"
            placeholder="请选择"
            mode="multiple"
            showArrow
            allowClear
            :maxTagCount="3"
            :options="staffGradeOps"
            @change="doSearch"
        >
        </SelectWithAll>
        <a-button style="margin-left: 50px" type="primary" @click="resetForm"
        >重置
        </a-button
        >
      </div>
    </div>
    <div class="table-box">
      <a-table
        :columns="columns"
        :data-source="tableList"
        rowKey="arveNO"
        :scroll="{
          y: tableHeight,
          x: tableWidth,
          scrollToFirstRowOnChange: true,
        }"
        :pagination="pagination"
      >
        <template #infoCollapse>
          <div class="collapse-cell">
            <div class="collapse-btn" @click="infoCollapse = !infoCollapse">
              <DoubleLeftOutlined v-if="infoCollapse" />
              <DoubleRightOutlined v-else />
            </div>
          </div>
        </template>
        <template #operation="{ record, index }">
          <a-space>
            <a @click="updateClick(record, index)" v-if="!isPassDate" v-auth="['system:GTEData:update']">更新</a>
            <a @click="infoClick(record)" v-auth="['system:GTEData:info']">个人信息</a>
          </a-space>
        </template>
      </a-table>
      <div class="batch-btn">
        <a-button type="primary" @click="calculateAllClick" v-if="!isPassDate" v-auth="['system:GTEData:calculateAll']">批量计算</a-button>
      </div>
    </div>
    <div ref="drawerRef">
      <a-drawer
        :width="600"
        v-model:visible="visible"
        title="数据更新"
        :bodyStyle="{ height: 'calc(100% - ' + (66 / 1920) * 100 + 'vw)' }"
        :destroyOnClose="true"
        :getContainer="() => $refs.drawerRef"
      >
        <UpdateForm
          :gteData="activeRow"
          :is-first="activeIndex === 0"
          :is-last="activeIndex === tableList.length - 1"
          @close-drawer="closeDrawer"
          @refresh-table="getTableList"
          @open-emp-info="openEmpInfo"
          @prev-click="prevClick"
          @next-click="nextClick"
        >
        </UpdateForm>
      </a-drawer>
    </div>
    <EmpInfo
      v-if="empVisible"
      :is-showing-emp-info="empVisible"
      :get-id="activeRow.userId"
      title="个人信息"
      :showwork-experience-col="false"
      :is-have-add="false"
      @close-emp-info="closeEmpInfo"
    />
  </section>
</template>

<script>
import {
  defineComponent,
  onUnmounted,
  reactive,
  ref,
  nextTick,
  onActivated,
  onMounted, computed
} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { dictionaryOptionsCode, downloadFile, useTableHeight } from "@/utils/common";
import { UpOutlined, DownOutlined, DoubleLeftOutlined, DoubleRightOutlined } from "@ant-design/icons-vue";
import UpdateForm from "@/views/home/ARVE/GTEData/components/UpdateForm";
import EmpInfo from "@/components/EmpInfo";
import { message } from "ant-design-vue";
import moment from "moment";

export default defineComponent({
  name: "GTEData",
  components: {
    EmpInfo,
    UpdateForm,
    UpOutlined,
    DownOutlined,
    DoubleLeftOutlined,
    DoubleRightOutlined
  },
  setup() {
    const collapse = ref(false);
    const staffGradeOps = ref([]);
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const formState = ref({
      fullName: "",
      queryType: undefined,
      grade: [],
      orgThreeTwo: undefined,
      orgFourOneOne: undefined,
      time: moment()
    });
    const getOrgList = () => {
      //查询org3.2/org4.2
      $api.getOrgThree_FourList().then((res) => {
        const { orgThreeTwo, orgFourOneOne } = res.data;
        orgThreeTwoOps.value = orgThreeTwo;
        orgFourOneOneOps.value = orgFourOneOne;
      });
    };
    const orgThreeTwoChange = () => {
      formState.value.orgFourOneOne = undefined;
      if (formState.value.orgThreeTwo) {
        $api
          .getOrgFour({ condition: formState.value.orgThreeTwo })
          .then((res) => {
            orgFourOneOneOps.value = res.data.orgFourOneOne;
          });
      } else {
        getOrgList();
      }
      doSearch();
    };

    const infoCollapse = ref(false);
    const collapseCellFunc = () => {
      return {
        style: {
          "background-color": "#F3F3F3"
        }
      };
    };
    const collapseTitleCellFunc = () => {
      return {
        style: {
          "background-color": "#FBFBFB"
        }
      };
    };
    const columns = computed(() => {
      let infoCols = [];
      if (infoCollapse.value) {
        infoCols = [
          {
            title: "Team",
            dataIndex: "orgThreeTwo",
            key: "orgThreeTwo",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 130
          },
          {
            title: "Offering",
            dataIndex: "orgFourOneOne",
            key: "orgFourOneOne",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 230
          },
          {
            title: "Grade",
            dataIndex: "grade",
            key: "grade",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 100
          },
          {
            title: () => {
              return (
                <div>
                  <div>ID</div>
                  <div>Number</div>
                </div>
              );
            },
            dataIndex: "idNumber",
            key: "idNumber",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 120
          },
          {
            title: () => {
              return (
                <div>
                  <div>From</div>
                  <div>Date</div>
                </div>
              );
            },
            dataIndex: "beginDate",
            key: "beginDate",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 150
          },
          {
            title: () => {
              return (
                <div>
                  <div>To</div>
                  <div>Date</div>
                </div>
              );
            },
            dataIndex: "endDate",
            key: "endDate",
            customHeaderCell: collapseCellFunc,
            customCell: collapseTitleCellFunc,
            ellipsis: true,
            align: "center",
            width: 150
          }
        ];
      }
      return [
        {
          title: "Name",
          dataIndex: "staffFullName",
          key: "staffFullName",
          width: 100,
          ellipsis: true,
          fixed: "left"
        },
        {
          dataIndex: "infoCollapse",
          key: "infoCollapse",
          align: "center",
          width: 0,
          slots: { title: "infoCollapse" },
          fixed: "left"
        },
        ...infoCols,
        {
          title: () => {
            return (
              <div>
                <div>Annual</div>
                <div>Leave</div>
              </div>
            );
          },
          dataIndex: "annualLeave",
          key: "annualLeave",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Other</div>
                <div>Leave</div>
              </div>
            );
          },
          dataIndex: "otherLeave",
          key: "otherLeave",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Public</div>
                <div>Holiday</div>
              </div>
            );
          },
          dataIndex: "publicHoliday",
          key: "publicHoliday",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Sick</div>
                <div>Leave</div>
              </div>
            );
          },
          dataIndex: "sickLeave",
          key: "sickLeave",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Unpaid</div>
                <div>Leave</div>
              </div>
            );
          },
          dataIndex: "unpaidLeave",
          key: "unpaidLeave",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: "BD",
          dataIndex: "bd",
          key: "bd",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: "BT",
          dataIndex: "billableTime",
          key: "billableTime",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: "NBT",
          dataIndex: "nonBillableTime",
          key: "nonBillableTime",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: "WWOC",
          dataIndex: "wwoc",
          key: "wwoc",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: "Funding",
          dataIndex: "funding",
          key: "funding",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: "Training",
          dataIndex: "training",
          key: "training",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Ext</div>
                <div>Solution</div>
                <div>Support</div>
              </div>
            );
          },
          dataIndex: "extSolutionSupport",
          key: "extSolutionSupport",
          width: 150,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>On-Project</div>
                <div>Support</div>
              </div>
            );
          },
          dataIndex: "onProjectSupport",
          key: "onProjectSupport",
          width: 150,
          ellipsis: true,
          align: "center"
        },
        {
          title: "COE",
          dataIndex: "coe",
          key: "coe",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: "Transition",
          dataIndex: "transition",
          key: "transition",
          width: 130,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Inventorty</div>
                <div>Investment</div>
              </div>
            );
          },
          dataIndex: "inventortyInvestment",
          key: "inventortyInvestment",
          width: 150,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Team</div>
                <div>Management</div>
              </div>
            );
          },
          dataIndex: "teamManagement",
          key: "teamManagement",
          width: 150,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Other</div>
                <div>Admin</div>
              </div>
            );
          },
          dataIndex: "otherAdmin",
          key: "otherAdmin",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>HC</div>
                <div>De-Commit</div>
              </div>
            );
          },
          dataIndex: "hcDeCommit",
          key: "hcDeCommit",
          width: 150,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Competence</div>
                <div>Solution</div>
              </div>
            );
          },
          dataIndex: "competenceSolution",
          key: "competenceSolution",
          width: 150,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Booster</div>
                <div>Program</div>
              </div>
            );
          },
          dataIndex: "boosterProgram",
          key: "boosterProgram",
          width: 150,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Timecard</div>
                <div>Issue-Missing</div>
              </div>
            );
          },
          dataIndex: "timecardIssueMissing",
          key: "timecardIssueMissing",
          width: 150,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Timecard</div>
                <div>Issue-Reject</div>
              </div>
            );
          },
          dataIndex: "timecardIssueReject",
          key: "timecardIssueReject",
          width: 150,
          ellipsis: true,
          align: "center"
        },
        {
          title: "Bench",
          dataIndex: "bench",
          key: "bench",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Grand</div>
                <div>Total</div>
              </div>
            );
          },
          dataIndex: "grandTotal",
          key: "grandTotal",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>Operation</div>
                <div>ARVE</div>
              </div>
            );
          },
          dataIndex: "operationArve",
          key: "operationArve",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>BT</div>
                <div>(BT+NBT,WWOC,Funding)</div>
              </div>
            );
          },
          dataIndex: "btNbtWwocFunding",
          key: "btNbtWwocFunding",
          width: 250,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>AV</div>
                <div>(Annual,No Pay Leave)</div>
              </div>
            );
          },
          dataIndex: "annualNopayleave",
          key: "annualNopayleave",
          width: 220,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>RM</div>
                <div>Comments</div>
              </div>
            );
          },
          dataIndex: "rmComments",
          key: "rmComments",
          customRender: ({ record }) => {
            const rmTypeLabel = record.rmTypeLabel
              ? record.rmTypeLabel + " "
              : "";
            const rmComments = record.rmComments ? record.rmComments : "";
            return rmTypeLabel + rmComments;
          },
          width: 150,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>BT</div>
                <div>FCST</div>
              </div>
            );
          },
          dataIndex: "btFcst",
          key: "btFcst",
          width: 100,
          ellipsis: true,
          align: "center"
        },
        {
          title: () => {
            return (
              <div>
                <div>BT FCST</div>
                <div>vs</div>
                <div>Actual BT GAP</div>
              </div>
            );
          },
          dataIndex: "btGap",
          key: "btGap",
          width: 150,
          ellipsis: true,
        },
        {
          title: "操作",
          dataIndex: "operation",
          slots: { customRender: "operation" },
          width: 140,
          fixed: "right"
        }
      ];
    });
    const tableList = ref([]);
    const pagination = reactive({
      current: 1,
      pageSize: 10,
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共${total}条`;
      },
      onChange: (page, pageSize) => {
        pagination.current = page;
        pagination.pageSize = pageSize;
        getTableList();
      },
      onShowSizeChange: (_, size) => {
        pagination.current = 1;
        pagination.pageSize = size;
        getTableList();
      }
    });
    const tableHeight = ref(0);
    const tableWidth = ref(0);
    const visible = ref(false);
    const activeRow = ref({});
    const activeIndex = ref();

    // 获取筛选框字典
    const getSelectOptions = () => {
      $api
        .getDictionaryList({
          params: {
            dictType: "grade"
          }
        })
        .then((res) => {
          staffGradeOps.value = dictionaryOptionsCode(res.data.grade);
        });
    };
    const { $api } = useGlobalPropertyHook();
    const doSearch = () => {
      pagination.current = 1;
      getTableList();
    };
    const getTableList = () => {
      const data = {
        fullName: formState.value.fullName,
        queryType: formState.value.queryType,
        grade: formState.value.grade,
        orgThreeTwo: formState.value.orgThreeTwo,
        orgFourOneOne: formState.value.orgFourOneOne,
        yearMonth: formState.value.time.format("YYYY-MM-DD")
      };
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize
      };
      $api.getGTEDataList(data, params).then((res) => {
        tableList.value = res.data.resource;
        pagination.total = res.data.total;
      });
    };

    const resetForm = () => {
      formState.value = {
        fullName: "",
        queryType: undefined,
        grade: undefined,
        orgThreeTwo: undefined,
        orgFourOneOne: undefined,
        time: moment()
      };
      getTableList();
    };

    const updateClick = (record, index) => {
      const params = {
        arveNo: record.arveNO,
        bt: record.btNbtWwocFunding,
        beginDate: record.beginDate,
        endDate: record.endDate,
        userId: record.userId
      };
      $api.calculateFCST(params).then((res) => {
        visible.value = true;
        record.btFcst = res.data.btFcst;
        record.btGap = res.data.btGap;
        activeRow.value = record;
        activeIndex.value = index;
      });
    };

    const closeDrawer = () => {
      visible.value = false;
    };

    const empVisible = ref(false);
    const openEmpInfo = () => {
      empVisible.value = true;
    };
    const closeEmpInfo = () => {
      empVisible.value = false;
    };

    const infoClick = (record) => {
      activeRow.value = record;
      openEmpInfo();
    };

    const calculateAllClick = () => {
      $api.calculateAll().then(() => {
        message.success("数据同步成功！");
        getTableList();
      });
    };

    const prevClick = () => {
      updateClick(
        tableList.value[activeIndex.value - 1],
        activeIndex.value - 1
      );
    };

    const nextClick = () => {
      updateClick(
        tableList.value[activeIndex.value + 1],
        activeIndex.value + 1
      );
    };

    const collapseClick = () => {
      collapse.value = !collapse.value;
      getSize();
    };

    const disabledDate = (current) => {
      return current && current > moment().endOf("day");
    };

    const isPassDate = computed(() => {
      console.log(moment().startOf("month"), formState.value.time < moment().startOf("month"));
      return formState.value.time < moment().startOf("month");
    });

    const arveTip = ref("");
    const getArveTips = () => {
      $api.getArveTips().then(res => {
        arveTip.value = res.data;
      });
    }

    const getSize = () => {
      nextTick(() => {
        const bodyWidth = document.body.clientWidth;
        tableWidth.value = bodyWidth - (93 / 1920) * bodyWidth;
        tableHeight.value = useTableHeight(true, ".ant-table", 120);
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getOrgList();
      getSelectOptions();
    });

    onActivated(() => {
      getArveTips();
      getSize();
      getTableList();
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });
    const exportClick = () => {
      const data = {
        fullName: formState.value.fullName,
        queryType: formState.value.queryType,
        grade: formState.value.grade,
        orgThreeTwo: formState.value.orgThreeTwo,
        orgFourOneOne: formState.value.orgFourOneOne,
        yearMonth: formState.value.time.format("YYYY-MM-DD")
      };
      $api.gteDataExport(data).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };

    return {
      collapse,
      staffGradeOps,
      formState,
      resetForm,
      infoCollapse,
      columns,
      tableList,
      pagination,
      tableHeight,
      tableWidth,
      getTableList,
      doSearch,
      visible,
      updateClick,
      activeRow,
      activeIndex,
      closeDrawer,
      empVisible,
      openEmpInfo,
      closeEmpInfo,
      infoClick,
      calculateAllClick,
      prevClick,
      nextClick,
      collapseClick,
      orgThreeTwoOps,
      orgFourOneOneOps,
      orgThreeTwoChange,
      exportClick,
      disabledDate,
      isPassDate,
      arveTip
    };
  }
});
</script>

<style lang="less" scoped>
//:deep(.ant-table-body) {
//  &:last-child {
//    height: calc(100vh - 158px);
//  }
//
//  &::-webkit-scrollbar {
//    width: 10px;
//    height: 10px;
//  }
//
//  &::-webkit-scrollbar-thumb {
//    width: 10px;
//    height: 10px;
//    background: #d0e8ff;
//    border: 1px solid #d0e8ff;
//    border-radius: 5px;
//  }
//}

.app-container {
  height: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
}

.search {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;

  .form {
    margin-top: 0;
  }
}

.form {
  margin-top: 16px;
  display: flex;
  align-items: center;

  .label {
    width: 110px;
    text-align: right;
    margin-right: 16px;
    font-family: "PingFang SC";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #2d3748;
  }

  .item {
    width: 128px;
    margin-right: 40px;
  }
}

.table-box {
  margin-top: 24px;
  position: relative;

  .batch-btn {
    position: absolute;
    left: 0;
    bottom: 10px;
  }
}

.collapse-cell {
  position: relative;
}

.collapse-btn {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #d0e2f3;
  color: #3182ce;
  position: absolute;
  left: -28px;
}

.arve-tip {
  margin-top: 24px;
  text-align: right;
  color: #f59723;
}
</style>
