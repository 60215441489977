<template>
  <div ref="saveModalRef">
    <a-modal
      :visible="visible"
      @cancel="closeModal"
      :width="520"
      centered
      :getContainer="() => $refs.saveModalRef"
    >
      <template #footer>
        <div class="footerBtn">
          <a-button type="primary" ghost @click="closeModal">取消</a-button>
          <a-button type="primary" @click="saveClick">保存</a-button>
          <a-button
            type="primary"
            @click="saveClick('prev')"
            v-show="btnType === 'prev'"
          >
            保存至上一个
          </a-button>
          <a-button
            type="primary"
            @click="saveClick('next')"
            v-show="btnType === 'next'"
          >
            保存至下一个
          </a-button>
        </div>
      </template>
      <div class="save-content">
        <div>
          <div class="title">是否确定修改并保存</div>
          <div>
            <a-checkbox v-model:checked="isSendMsg"
              >系统发送消息提醒</a-checkbox
            >
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from "vue";

export default defineComponent({
  name: "SaveModal",
  props: {
    visible: Boolean,
    btnType: String,
  },
  emits: ["close-modal", "save-click"],
  components: {},
  setup(props, { emit }) {
    watch(
      () => props.visible,
      (val) => {
        if (val) {
          isSendMsg.value = true;
        }
      }
    );
    const closeModal = () => {
      emit("close-modal");
    };

    const isSendMsg = ref(true);

    const saveClick = (operate) => {
      emit("save-click", operate);
    };

    return {
      closeModal,
      isSendMsg,
      saveClick,
    };
  },
});
</script>

<style lang="less" scoped>
.footerBtn {
  text-align: center;
  margin: 5px;
}
.save-content {
  margin: 40px 0 0;
  text-align: center;

  .title {
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 24px;
  }

  .ant-checkbox-wrapper {
    font-size: 18px;
  }
}
</style>
